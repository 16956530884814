<template>
	<div class="home-concentric-image flex flex--align flex--center" :style="`border-color: ${brandColors[borderColor]};`">
		<slot>
			<img v-if="src" loading="lazy" :src="src" :alt="alt" width="300" height="300">
		</slot>
		<svg width="529" height="530" viewBox="0 0 529 530" fill="none">
			<path d="M144.1 376.309C118.535 348.632 103.288 312.99 100.928 275.386C98.5677 237.782 109.238 200.514 131.142 169.857C153.046 139.2 184.845 117.028 221.185 107.075C257.524 97.1219 296.183 99.9955 330.652 115.212" :stroke="brandColors[ringColors[0]]" stroke-width="30.266" :class="`${rotateDirection} slow`" transform="rotate(90 50 50)" />
			<path d="M303.416 53.1365C338.491 59.5801 371.42 74.6263 399.25 96.9266C427.079 119.227 448.941 148.084 462.874 180.912C476.808 213.739 482.378 249.511 479.085 285.021C475.792 320.531 463.74 354.669 444.008 384.375" :stroke="brandColors[ringColors[1]]" stroke-width="0.818" stroke-dasharray="2.45 8.18" class="counterclockwise" />
		</svg>
	</div>
</template>
<script>
	import brandColors from 'src/utilities/brand-colors';
	export default {
		name: 'CConcentricImage',
		props: {
			src: {
				type: String,
				default: null
			},
			alt: {
				type: String,
				default: null
			},
			rotateDirection: {
				type: String,
				validator: value => {
					return ['clockwise', 'counterclockwise'].includes(value);
				},
				default: 'clockwise'
			},
			ringColors: {
				type: Array,
				default: () => ['thetis', 'hitchcock', 'peach']
			},
			borderColor: {
				type: String,
				default: 'platon'
			}
		},
		data () {
			return {
				brandColors
			};
		}
	};
</script>

<style lang="scss">
.home-concentric-image {
	position: relative;
	width: 300px;
	max-width: 100%;

	img {
		position: relative;
		max-width: 100%;
		border-width: 4px;
		border-style: solid;
		border-radius: 50%;
	}

	svg {
		position: absolute;
		z-index: $z-1;
		width: 530px;
		max-width: 177%;

		path {
			transform-origin: center center;
		}
	}
}
</style>