<template>
	<section class="home-data wrapper py-6 b9:flex flex--align">
		<c-concentric-image
			:src="require('@cognitoforms/shared-components/src/assets/graphics/images/home-data.jpg')"
			alt="Manager discussing business with his team"
			class="flex--no-shrink mx-auto mb-5 b9:bm-0"
		/>
		<div>
			<h2 class="gilroy matins">Accurate and actionable data, every&nbsp;time</h2>
			<h3 class="light">Struggling with incomplete, outdated, or inconsistent data?</h3>
			<p class="sz-4">Don’t wait for the budget, developers, or IT to develop a custom solution. Take charge and build a no-code solution that is as unique as your processes and harness the flow of information.</p>
			<c-cta
				id="86"
				button-text="Get Started"
				type="Sign Up"
				position="middle"
				class="button button--primary sz-5 py-2"
			/>
		</div>
	</section>
</template>
<script>
	import CConcentricImage from './HomeConcentricImage.vue';
	import CCta from 'src/components/content/cta.vue';

	export default {
		name: 'CData',
		components: {
			CConcentricImage,
			CCta
		}
	};
</script>

<style lang="scss">
.home-data {
	gap: $space__6;

	@include b-down($b6) {
		overflow: hidden;
	}
}
</style>