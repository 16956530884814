<template>
	<section class="screenshots pb-4">
		<div class="screenshots__wrapper">
			<div class="screenshots__tabs">
				<ol class="flex flex--sb over">
					<li
						v-for="(tab, i) in tabs"
						:id="'screenshot--' + tab.icon"
						:key="tab.icon"
						class="screenshots__tab pointer text-center mb-3"
						:class="{'is-active': i === activeTab}"
						@click="activateTab(i)"
					>
						<div class="screenshots__icon">
							<inline :svg="tab.icon" />
						</div>
						<div class="sz-2">{{ tab.title }}</div>
					</li>
				</ol>
			</div>
			<ol class="screenshots__images under mt-0">
				<li
					v-for="(tab, i) in tabs"
					:key="i"
					class="mb-2"
					:class="{'hide': i !== activeTab}"
				>
					<img
						v-for="(image, j) in tab.images"
						:key="image.name"
						:alt="image.alt"
						:class="[
							j === 0 ? 'screenshots__base-image b7:show' : 'screenshots__floating-image',
							`screenshots__${tab.icon}-${j}`,
							{'screenshots__image--phone': image.phone }
						]"
						:src="require('@cognitoforms/shared-components/src/assets/graphics/images/home/screenshots/' + image.name)"
						:width="j === 0 ? '1200' : image.phone ? '786' : null"
						:height="j === 0 ? '650' : image.phone ? '949' : null"
						loading="lazy"
						:srcset="image.srcSet ? `${require('@cognitoforms/shared-components/src/assets/graphics/images/home/screenshots/' + image.srcSet[0])} 252w, ${require('@cognitoforms/shared-components/src/assets/graphics/images/home/screenshots/' + image.srcSet[1])} 378w, ${require('@cognitoforms/shared-components/src/assets/graphics/images/home/screenshots/' + image.srcSet[2])} 503w, ${require('@cognitoforms/shared-components/src/assets/graphics/images/home/screenshots/' + image.name)} 786w` : null"
						:sizes="image.srcSet ? '(max-width: 700px) 60vw, 800px' : null"
					/>
					<img
						v-if="tab.icon !== 'generated-documents'"
						:alt="tab.images[0].alt"
						class="screenshots__base-image--mobile b7:hide"
						:class="`screenshots__${tab.icon}-0`"
						:src="require(`@cognitoforms/shared-components/src/assets/graphics/images/home/screenshots/${tab.images[0].name.replace('.webp', '--mobile.webp')}`)"
						width="1100"
						height="1050"
						loading="lazy"
					/>
					<div
						v-else
						class="screenshots__mobile-placeholder b7:hide"
					>
					</div>
					<div
						v-for="(text, j) in tab.text"
						:key="j"
						class="screenshots__info matins"
						:class="`screenshots__${tab.icon}-info-${j}`"
					>
						<div>{{ text }}</div>
					</div>
				</li>
			</ol>
		</div>
	</section>
</template>

<script>

	export default {
		name: 'Screenshots',
		props: { tabs: { type: Array, required: true }	},

		data() {
			return {
				activeTab: 0
			};
		},
		methods: {
			activateTab(i) {
				this.activeTab = i;
			}
		}
	};
</script>

<style lang="scss">
:where(.home .screenshots) {

	img {
		display: block;
	}
}

:where(.home) {
	@keyframes slide-up {

		0% {
			transform: translateY(100%);
		}

		100% {
			transform: translateY(0);
		}
	}

	@keyframes focus-in {

		0% {
			filter: blur(3px);
			opacity: .8;
		}

		100% {
			filter: blur(0);
			opacity: 1;
		}
	}

	@keyframes fade-in {

		0% {
			opacity: 0;
			transform: scale(.9);
		}

		100% {
			opacity: 1;
			transform: scale(1);
		}
	}

	@keyframes pop-in {

		0% {
			opacity: 0;
			transform: scale(.7);
		}

		100% {
			opacity: 1;
			transform: scale(1);
		}
	}

	@keyframes emanate {

		0% {
			opacity: 1;
			transform: translateX(calc(-50% + 1px)) scale(.5);
		}

		100% {
			opacity: 0;
			transform: translateX(calc(-50% + 1px)) scale(2);
		}
	}

	@keyframes emanate-left {

		0% {
			opacity: 1;
			transform: translateY(calc(-50% + 1px)) scale(.5);
		}

		100% {
			opacity: 0;
			transform: translateY(calc(-50% + 1px)) scale(2);
		}
	}

	.screenshots {
		$info-l: 17.5em;
		$info-xl: 22.5em;
		$mobile-bp: $b7;
		background: linear-gradient(transparent, $air);
		border-bottom: 1px solid $thetis;
		margin: 0 auto;
		overflow: hidden;

		&__wrapper {
			width: clamp(900px, 70vw, 1250px);
			max-width: calc(100% - 64px);
			margin: 0 auto;
		}

		&__icon {
			display: flex;
			align-content: center;
			justify-content: center;
			width: 45px;
			height: 45px;
			border-radius: 50%;
			margin: 0 auto $space__1;
			color: $deep;
			transition: .2s;

			.i-simple {
				width: 1.4em;
			}
		}

		&__tabs {
			overflow: auto;

			@include b-down($b6) {
				@include unwrap;
			}

			ol {
				@include b-down($b6) {
					min-width: 700px;
					padding-right: $space__5;
					padding-left: $space__5;
				}

				@include b-up($b7) {
					padding: 0 $space__4;
				}

				// Ensure crop looks scrollabe on mobile devices
				@media (max-width: 620px) {
					width: 160vw;
				}

				@media (max-width: 415px) {
					width: 215vw;
				}
			}

			& li:not(.is-active):hover .screenshots__icon {
				background: $mapplethorpe;
			}

			.is-active {
				color: $deep;

				.screenshots__icon {
					background: $poseidon;
					color: white;
				}
			}
		}

		&__images {
			position: relative;
			padding-bottom: 54.1%;

			@include b-down($mobile-bp) {
				padding-bottom: 95.2%;
			}

			li {
				position: absolute;
				width: 100%;
			}
		}

		&__base-image,
		&__base-image--mobile,
		&__mobile-placeholder {
			display: block;
			width: 100%;
			background: $air;
			border: 1px solid $hitchcock;
			box-shadow: -1px 1px 6px rgba($poseidon, .2), -30px 30px 98px rgba($poseidon, .07);
			animation: focus-in .5s;
		}

		&__info,
		&__floating-image,
		.screenshots-phone {
			position: absolute;
		}

		&__info {
			position: absolute;
			z-index: $z2;
			width: 2px;
			height: 93px;
			background: $benjamin;
			outline: 2px solid white;
			opacity: 0;
			font-size: clamp(modular-scale(2), 1.1vw, modular-scale(3));
			animation: fade-in .5s .9s forwards;

			&::before {
				position: absolute;
				bottom: 0;
				width: 1em;
				height: 1em;
				background: $benjamin;
				border-radius: 50%;
				transform: translateX(calc(-50% + 1px));
				content: '';
			}

			&::after {
				position: absolute;
				bottom: 0;
				width: 1em;
				height: 1em;
				background: transparent;
				border: 1px solid $benjamin;
				border-radius: 50%;
				transform: translateX(calc(-50% + 1px)) scale(.5);
				content: '';
				animation: emanate 3s 1s infinite;
			}

			& > div {
				position: absolute;
				bottom: 93px;
				left: -50%;
				min-width: 15.8em;
				max-width: 350px;
				background: $harriet;
				border: 2px solid $benjamin;
				box-shadow: -1px 1px 4px rgba($midnight, .15), -7px 7px 10px rgba($midnight, .05);
				padding: clamp(6px, 1.1vw, 16px);
				transform: translateX(-50%);
			}
		}

		@mixin info-top {

			&::before,
			&::after {
				position: absolute;
				bottom: initial;
				top: 0;
			}

			& > div {
				bottom: initial;
				top: 93px;
			}
		}

		@mixin info-left {
			width: 93px;
			height: 2px;

			&::before,
			&::after {
				position: absolute;
				bottom: initial;
				right: -2px;
				transform: translateY(calc(-50% + 1px));
			}

			&::after {
				animation: emanate-left 3s 1s infinite;
			}

			& > div {
				bottom: initial;
				left: 0;
				transform: translate(-100%, -50%);
			}
		}

		&__floating-image {
			bottom: -3%;
			right: -20px;
			max-width: 60%;
			max-height: 80%;
		}

		&__floating-image:not(.screenshots__image--phone) {
			z-index: $z1;
			box-shadow: 0 0 24px rgba($ansel, .15), -1px 1px 4px rgba($ansel, .2);
			opacity: 0;
			animation: pop-in .5s .2s forwards;
		}

		.screenshots__image--phone {
			display: block;
			bottom: calc(-2rem - 17px);
			right: -30px;
			width: auto;
			max-width: 430px;
			height: auto;
			max-height: 92%;
			transform: translateY(100%);
			animation: slide-up .6s .5s forwards;
		}

		.screenshots__base-image.fade-leave-active {
			position: absolute;
		}

		&__signature {

			&-info-0 {
				left: -20px;
				top: 53%;
				height: 0;
				outline: 0;

				& > div {
					bottom: 0;
					min-width: $info-l;
					transform: none;
				}

				&::before,
				&::after {
					display: none;
				}

				@include b-down($mobile-bp) {
					top: 26%;
				}
			}
		}

		&__credit-card {

			&-1 {
				left: -20px;

				@include b-down($mobile-bp) {
					display: none;
				}
			}
		}

		&__generated-documents {

			&-info-0 {
				$stem-length: min(120px, 7.3vw);
				bottom: 27.5%;
				left: 22%;
				height: $stem-length;

				& > div {
					bottom: $stem-length;
					min-width: $info-xl;
					transform: translateX(max(-82%, -22vw));
				}

				@include b-down($mobile-bp) {
					bottom: 13%;
					height: 0;
					outline: 0;

					&::before,
					&::after {
						display: none;
					}
				}
			}

			&-1 {
				@include b-down($mobile-bp) {
					bottom: -1%;
					left: initial;
					right: initial;
					max-width: none;
					max-height: 95%;
					transform: translateX(23%) rotate(5deg) !important;
				}
			}
		}

		&__workflow {

			&-0 {
				background: linear-gradient($air, transparent);
				border: none;
				box-shadow: none;
			}

			&-1 {
				right: 5%;
				top: 4%;
				max-width: 30%;

				@include b-down($mobile-bp) {
					display: none;
				}
			}

			&-2 {
				bottom: -25px;
				right: -25px;
				max-width: 33%;

				@include b-down($mobile-bp) {
					display: none;
				}
			}
		}

		&__tasks {

			&-info-0 {
				$stem-length: min(40px, 8vw);
				left: 17%;
				top: 25.5%;
				height: $stem-length;

				@include info-top;

				& > div {
					top: $stem-length;
					min-width: $info-l;
				}

				@include b-down($mobile-bp) {
					left: 35%;
					top: 31%;

					& > div {
						min-width: 145px;
						transform: translateX(-130px);
					}
				}
			}

			&-info-1 {
				$stem-length: min(140px, 10vw);

				@include b-up($mobile-bp) {
					@include info-left;
					bottom: calc(20.3%);
					right: calc(min(24%, 250px) + 11%);
					width: $stem-length;
				}

				@include b-up(1350px) {
					bottom: 20.5%;
					right: 35%;
				}

				@include b-down($mobile-bp) {
					$stem-length: min(60px, 4vw);

					@include info-left;
					left: 40%;
					top: 83%;
					width: 30px;

					& > div {
						right: $stem-length;
						top: 25px;
						min-width: 12em;
					}
				}
			}
		}

		&__data {

			&-info-0 {
				$stem-length: min(140px, 18vw);
				left: 22%;
				top: 30%;
				height: $stem-length;

				@include info-top;

				& > div {
					top: $stem-length;
					min-width: $info-l;
				}

				@include b-down($mobile-bp) {
					left: 35%;
					top: 27%;

					& > div {
						min-width: 145px;
						transform: translateX(-130px);
					}
				}
			}

			&-info-1 {
				$stem-length: min(80px, 6vw);
				left: 43.3%;
				top: 14.5%;
				height: $stem-length;

				@include info-top;

				& > div {
					top: $stem-length;
				}

				@include b-down($mobile-bp) {
					left: initial;
					right: 27%;
				}

				@include b-down($b5) {

					& > div {
						min-width: 14.7em;
						transform: translateX(-38%);
					}
				}
			}

			&-1 {
				max-width: 425px;

				@include b-down($mobile-bp) {
					max-width: 65%;
				}
			}
		}

		&__approvals {

			&-info-0 {
				$stem-length: max(30px, 3vw);
				bottom: 13%;
				left: 15.3%;
				height: $stem-length;

				& > div {
					bottom: $stem-length;
					min-width: 15em;
				}

				@include b-down($mobile-bp) {
					bottom: 13.5%;
					left: 3px;
					outline: 0;

					& > div {
						transform: translateX(-10%);
					}
				}

				@include b-down($b6) {

					& > div {
						min-width: 14.1em;
					}
				}
			}

			&-info-1 {
				$stem-length: min(160px, 12vw);

				@include b-up($mobile-bp) {
					@include info-left;
					bottom: 7.3%;
					right: min(24%, 250px);
					width: $stem-length;
				}

				@include b-range(1350px, 1630px) {
					bottom: 8%;
				}

				@include b-down($mobile-bp) {
					$stem-length: 34vw;
					left: 59%;
					top: 54%;
					height: $stem-length;

					& > div {
						bottom: $stem-length;
					}
				}

				@include b-range($b5, $mobile-bp) {
					top: 52%;
				}

				& > div {
					right: $stem-length;
					min-width: 15.2em;
				}
			}
		}

		// When a base image is not needed for mobile (E.g. Generated Documents)
		&__mobile-placeholder {
			width: 100%;
			padding-bottom: 95.2%;
		}
	}
}
</style>