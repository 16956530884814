<template>
	<section class="hero" :class="{'hero--impact': impactMade}">
		<div class="relative">
			<div class="hero__heading-container">
				<h1 class="hero__heading deep light gilroy text-center">
					A powerful no-code online form<br class="b10:hide" /> builder that automates the steps to <span class="hero__text block heavy matins">move your work <span class="nowrap">forward
						<span class="hero__arrow-container">
							<svg ref="orbit" class="hero-orbit under" width="2000" height="2000" viewBox="0 0 2000 2000" fill="none">
								<g class="concentric__container">
									<!--
										Firefox was displaying the circles slightly larger. Hacked it since Firefox also doesn't
										support `r` in CSS but Chrome does. That's why the radii are different in svg and css.
									-->
									<circle class="hero-orbit__1" r="890" cx="1000" cy="1000" pathLength="1000" />
									<circle class="hero-orbit__2" r="537" cx="1000" cy="1000" pathLength="1000" />
									<circle class="hero-orbit__3" r="512" cx="1000" cy="1000" pathLength="1000" />
									<circle class="hero-orbit__4" r="957" cx="1000" cy="1000" />
									<circle class="hero-orbit__5" r="807" cx="1000" cy="1000" />
									<circle class="hero-orbit__6" r="962" cx="1000" cy="1000" />
									<circle class="hero-orbit__main" r="992" cx="1000" cy="1000" />
								</g>
							</svg>
							<svg class="hero__arrow" width="58" height="49" viewBox="0 0 58 49" fill="none">
								<path d="M33.3015 0.564453L57.5025 24.7655L33.3015 48.9665H17.3288L35.7216 30.5737H0.226807V18.9572H35.7216L17.3288 0.564453H33.3015Z" fill="currentColor" />
							</svg>
							<svg v-if="impactMade" class="hero__check absolute b10:show" width="200" height="200" viewBox="0 0 200 200" fill="none">
								<circle class="hero__check-emanation" r="98" cx="100" cy="100" fill="none" />
								<circle class="hero__check-circle" r="40" cx="100" cy="100" />
								<path class="hero__check-mark" d="M125.451 85.2173L90.4559 119.152L74.5491 103.49" stroke="#234652" stroke-width="5" stroke-linejoin="round" />
							</svg>
						</span>
					</span>
					</span>
				</h1>
			</div>
			<div class="wrapper wrapper--4">
				<ol class="hero__bullets center">
					<li>
						<inline svg="complex-icons/build" />
						<div class="hero__bullet-text"><span>Build</span> effortless online forms as unique as your process.</div>
					</li>
					<li>
						<inline svg="complex-icons/data-collection" />
						<div class="hero__bullet-text"><span>Collect</span> consistent data from your customers and employees.</div>
					</li>
					<li>
						<inline svg="complex-icons/workflow" />
						<div class="hero__bullet-text"><span>Take action</span> at the right time with Workflow automation and Tasks.</div>
					</li>
				</ol>
			</div>
		</div>
		<div class="over text-center">
			<c-cta
				id="75"
				button-text="Get Started"
				url="/signup"
				type="Sign Up"
				position="top"
				class="hero__button button button--primary sz-4 b6:sz-5"
			/>
			<div class="sz-2 mt-2">14-day free trial. No credit card required.</div>
		</div>
	</section>
</template>

<script>
	import CCta from '/src/components/content/cta.vue';

	export default {
		name: 'Hero',
		components: { CCta },
		data() {
			return {
				impactMade: false
			};
		},
		mounted() {
			window.addEventListener('scroll', this.setImpactNumber);
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.setImpactNumber);
		},
		methods: {
			setImpactNumber() {
				// There is a CSS var below so you can set exactly what scroll position "impact" on the arrow is made.
				const impactNumber = +window.getComputedStyle(this.$refs.orbit).getPropertyValue('--impact-number');
				if (impactNumber > -1)
					this.impactMade = window.scrollY > impactNumber;
			}
		}
	};
</script>

<style lang="scss">
$home-cta-width: 300px;

.home {

	.hero {
		padding-bottom: clamp(150px, 29vw, 450px);

		@include b-down(1450px) {

			&.hero--impact {

				.hero__check {
					transform: scale(.8);
					transform-origin: center;
				}
			}
		}

		@include b-down($b10) {
			overflow: hidden;
		}

		h1 {
			font-size: clamp(30px, 2.75vw, 40px);

			&::before {
				display: block;
				position: absolute;
				bottom: -60px;
				left: -20px;
				top: -50px;
				z-index: $z-1;
				width: calc(100% + 40px);
				background: linear-gradient(white, white 75%, transparent);
				content: '';

				@include b-down($b10) {
					display: none;
				}
			}

			@include b-down($b10) {
				font-size: clamp(21px, 3.8vw, 300px);
			}
		}

		&__text {
			margin-top: 10px;
			font-size: min(11vw, 2.58em);
			line-height: 1.1;

			&::first-letter {
				text-transform: capitalize;
			}
		}

		&:not(.hero--impact) &__text {
			@include b-up($b10) {
				background: linear-gradient(90deg, $deep calc(.5% * max(var(--scroll, 0), 0)), $matins calc(.7% * max(var(--scroll, 0), 0)));
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		&.hero--impact &__text {
			animation: flash .5s forwards;
		}

		&__arrow {
			width: .8em;
			height: auto;
		}

		@keyframes flash {

			0% {
				opacity: .7;
			}

			100% {
				opacity: 1;
			}
		}

		@keyframes grow {

			0% {
				transform: scale(.2);
			}

			95% {
				transform: scale(1.2);
			}

			100% {
				transform: scale(1);
			}
		}

		@keyframes emanation {

			0% {
				opacity: 1;
				transform: scale(.4);
			}

			100% {
				opacity: 0;
				transform: scale(1);
			}
		}

		&__check {
			bottom: -48px;
			right: -56px;
			width: 2em;

			@include b-down(1140px) {
				bottom: -57px;
				right: -.45em;
			}

			&-circle {
				transform-origin: 50% 50%;
				animation: grow .2s forwards;
				fill: $thetis;
			}

			&-mark {
				transform: scale(0);
				transform-origin: 50% 50%;
				animation: grow .2s .2s forwards;
				stroke: $matins;
				stroke-linecap: round;
			}

			&-emanation {
				transform-origin: 50% 50%;
				animation: emanation .5s forwards;
				stroke: $thetis;
				stroke-width: 20;
				stroke-dasharray: 2 20;
			}
		}

		&__bullets {
			display: flex;
			max-width: 1000px;
			gap: $space__5;

			@include b-down($b10) {
				padding: 5.5vh 0;
			}

			@include b-down($b6) {
				flex-direction: column;
				max-width: $home-cta-width;
			}

			li {
				display: flex;
				align-content: center;
				align-items: flex-start;
				gap: $space__4;

				svg [fill] {
					fill: $abraham;
				}

				&:nth-child(2) {

					svg [fill] {
						fill: $pudding;
					}
				}

				&:nth-child(3) {

					svg [fill] {
						fill: $thetis;
					}
				}

				@include b-range($b6, $b9) {
					flex-direction: column;
					text-align: center;

					.i-complex {
						align-self: center;
					}
				}
			}

			.i-complex {
				flex-shrink: 0;
				width: min(10vw, 70px);
			}
		}

		&__bullet-text span {
			display: block;
			color: $deep;
			font-family: $header;
			font-size: modular-scale(4);
			font-weight: $heavy;

			@include b-up($b6) {
				font-size: modular-scale(5);
			}
		}

		&__button {
			display: block;
			max-width: $home-cta-width;
			margin: 0 auto;
		}

		@include b-up($b10) {

			&__heading {
				position: relative;
				width: fit-content;
				margin: 0 auto;
			}

			&__heading-container {
				position: sticky;
				left: 0;
				top: 24vh;
				z-index: $z1;
				width: 100%;
			}

			.hero__bullets {
				margin-top: 29vh;
				margin-bottom: 7vh;
			}
		}

		@media (max-width: $b10) and (min-height: $b4) {

			&__heading-container {
				@include page-header-space;

				h1 {
					margin-top: 3vh;
				}
			}
		}

		:root &-orbit {
			--impact-number: 145;

			// Using translate and scale to place the orbit so that it "perfectly" hits the arrow.
			// Can't use overflow:hidden because the h1 is position:sticky. This means the right
			// side of the SVG has to end before the edge of the viewport. That takes some hand-holding.

			// Slightly modified from Concentric.vue
			position: absolute;
			right: 166px;
			top: calc(-50% - 7.85em);
			z-index: $z-1;
			transform: translate(-50%, -50%) scale(2.2);
			pointer-events: none;

			.concentric__container > * {
				transform-origin: center;
			}

			.concentric__container {
				transform: translateX(211px) scale(1.03);
			}

			// Tweak placement on medium viewports
			@include b-range(1300px, 1400px) {
				right: 155px;
			}

			@include b-down(1300px) {
				right: 121px;
				top: calc(-50% - 7.35em);
				transform: translate(-50%, -50%) scale(2.145);
			}

			@include b-down($b10) {
				right: 166px;
				top: -140px;
				transform: translate(-47%, -56%) scale(2.4);

				--impact-number: -1;
			}

			// End tweaks

			&__main {
				opacity: calc(300 - var(--scroll));
				transform: rotate(calc((var(--scroll, 0) * -.465deg) + 111deg));
				stroke: $thetis;
				stroke-width: 3;
				stroke-dasharray: 60% 300%;
				r: 999;
			}

			&__1 {
				// stroke-dasharray: Setting PathLength to 1000 on the svg element means there are 1000 units. Add them in groups with variables.
				// The 0 and N numbers at the end of the string is for a gap in the circle.
				$dash-array: 1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4; // 50
				transform: rotate(calc((var(--scroll, 0) * -.04deg) + 100deg));
				stroke: $powder;
				stroke-width: 200;
				stroke-dasharray: $dash-array, $dash-array, $dash-array, $dash-array, $dash-array, $dash-array, $dash-array, $dash-array, 0, 600;
				r: 898;
			}

			&__2 {
				$dash-array: .5,1,.5,1,.5,1,.5,1,.5,1,.5,1,.5,1,.5,1,.5,1,.5,1; // 15
				transform: rotate(calc((var(--scroll, 0) * -.5deg) + 97deg));
				stroke: $pudding;
				stroke-width: 1;
				stroke-dasharray: $dash-array, $dash-array, $dash-array, $dash-array, $dash-array, 0, 925;
				r: 545;

				@media (min-width: 2100px) and (max-width: 2400px) {
					r: 460;
				}

				@media (max-width: 2200px) {
					r: 555;
				}
			}

			&__3 {
				$dash-array: .5,5,.5,5,.5,5,.5,5,.5,5,.5,5,.5,5,.5,5,.5,5,.5,5,.5,5,.5,5; // 66
				transform: rotate(calc((var(--scroll, 0) * -.02deg) + 45deg));
				stroke: $mapplethorpe;
				stroke-width: 20;
				stroke-dasharray: $dash-array, $dash-array, $dash-array, 0, 802;
				r: 520;

				@media (max-width: 2150px) {
					transform: rotate(calc((var(--scroll, 0) * -.02deg) + 25deg));
				}

				@media (max-width: 1500px) {
					display: none;
				}
			}

			&__4 {
				transform: rotate(calc((var(--scroll, 0) * -.05deg) + 175deg));
				stroke: white;
				stroke-width: 35;
				r: 965;
				stroke-dasharray: 1800px 3000px;
			}

			&__5 {
				transform: rotate(calc((var(--scroll, 0) * -.1deg) + 145deg));
				stroke: $brady;
				stroke-width: 1px;
				r: 815;
				stroke-dasharray: 2000px 3000px;
			}

			&__6 {
				transform: rotate(calc((var(--scroll, 0) * -.1deg) + 170deg));
				stroke: $cider;
				stroke-width: 10;
				r: 970;
				stroke-dasharray: 2000px 3000px;
			}
		}

		&--impact {

			.hero__text {
				color: $deep !important;
			}

			.hero__arrow {
				opacity: 0;
			}
		}

		// Fade Concentric lines when they'd be under or too close to our logo
		@media (min-width: 2850px) and (max-width: 4150px) and (min-height: 900px),
		(min-width: 3000px) and (max-width: 4000px) and (max-height: 900px) {

			.hero-orbit__1,
			.hero-orbit__5 {
				opacity: calc(var(--scroll, 0) * .01 + .2);
			}

			.hero-orbit__main {
				opacity: calc(var(--scroll, 0) * .01 + .1);
			}

			.hero-orbit__6 {
				opacity: calc(var(--scroll, 0) * .01 + .5);
			}
		}

		@media (min-width: 2850px) and (min-height: 900px),
		(min-width: 1750px) and (max-height: 900px) {

			.hero-orbit__1 {
				opacity: calc(var(--scroll, 0) * .01 + .2);
			}

			.hero-orbit__5 {
				opacity: calc(var(--scroll, 0) * .01);
			}
		}
	}
}
</style>