export default {
	'burnt-out': '#662108',
	'burnt-jaywalk': '#a3340d',
	'jaywalk': '#d85427',
	'blossom': '#f9a241',
	'pudding': '#f9cf62',
	'summer': '#ffe49d',
	'peach': '#fff1d0',
	'cider': '#fff7e3',
	'midnight': '#172d35',
	'matins': '#234652',
	'deep': '#09a098',
	'poseidon': '#00b3ab',
	'thetis': '#77e6e0',
	'air': '#d0efec',
	'powder': '#e3f8f8',
	'puff': '#f1fcfc',
	'ulysses': '#347208',
	'benjamin': '#94cc3f',
	'abraham': '#d1ed7e',
	'harriet': '#e7f5be',
	'alexander': '#f6fbe5',
	'ansel': '#55727b',
	'platon': '#798f8f',
	'casablanca': '#92b8b8',
	'hitchcock': '#bdcccc',
	'lange': '#d0dbdb',
	'brady': '#dfeaea',
	'mapplethorpe': '#edf4f4',
	'arnold': '#f7fafa'
};