<template>
	<section class="home-productivity-stats wrapper text-center pt-6 pb-5">
		<h2 class="matins gilroy">Increase productivity</h2>
		<h3 class="light mb-4">Move the work forward at lightning speed and unbelievable accuracy.</h3>
		<div class="home-productivity-stats__content flex flex--center flex--wrap">
			<c-stats
				amount="90%"
				description="90% are able to act quicker on incoming data/forms."
				portrait
				class="home-productivity-stats__stat flex--no-shrink mb"
			/>
			<c-stats
				amount="33%"
				description="8 hours saved each week."
				label="8"
				portrait
				class="home-productivity-stats__stat flex--no-shrink mb"
			/>
			<c-stats
				amount="80%"
				description="80% have more accurate, consistent, and complete data."
				portrait
				class="home-productivity-stats__stat flex--no-shrink mb"
			/>
		</div>
	</section>
</template>

<script>
	import CStats from 'src/components/website/Stats.vue';
	export default {
		name: 'CProductivityStats',
		components: {
			CStats
		}
	};
</script>

<style lang="scss">
.home-productivity-stats {

	.stats {
		flex-grow: 1;

		&__description {
			font-family: $website-text-type;
			font-size: $size__4;
			font-weight: $heavy;
		}

		@include b-down($b4) {

			&__content {
				margin-top: $gutter * .5;
			}

			&__amount-container {
				transform: scale(.95);
			}
		}
	}

	&__stat {
		max-width: 350px;

		&:nth-child(1) {

			path {

				&:nth-child(2) {
					stroke: $alexander;
					stroke-width: 40px;
					scale: .9;
				}

				&:nth-child(3) {
					stroke: $harriet;
				}
			}
		}

		&:nth-child(2) {

			path {

				&:nth-child(2) {
					transform: rotate(calc(var(--scroll) * .05deg));
					stroke-width: 10px;
					scale: .9;
				}

				&:nth-child(3) {
					transform: rotate(calc(var(--scroll) * -.1deg + 90deg));
					stroke: $brady;
				}
			}
		}

		&:nth-child(3) {

			path {

				&:nth-child(2) {
					transform: rotate(calc(var(--scroll) * -.1deg - 45deg));
					stroke: $puff;
					stroke-width: 10px;
				}

				&:nth-child(3) {
					transform: rotate(calc(var(--scroll) * .05deg - 45deg));
					stroke: $harriet;
				}
			}
		}
	}
}
</style>