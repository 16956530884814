<template>
	<section class="home-solutions over py-6">
		<div class="wrapper--2 px-3 mx-auto mb-4 text-center">
			<h2 class="gilroy heavy sz-7 b7:sz-8">Solutions as unique as your&nbsp;work</h2>
			<p class="lede sz-5 b7:sz-6">Build powerful online forms and customize workflows for your unique team and&nbsp;customers.</p>
		</div>
		<div class="wrapper">
			<ul class="b0-b5:unwrap b5:flex b5:flex--2-cols b10:flex--3-cols stroke-matins mb-3">
				<li v-for="solution in solutions" :key="solution.title" class="mb-0">
					<c-cta
						:id="solution.ctaId"
						:url="solution.link"
						position="bottom"
						type="Templates"
						class="home-solutions__solution flex no-underline p-4 h-100 bg-arnold matins"
						:class="`fill-${solution.fill}`"
					>
						<inline :svg="`complex-icons/${solution.illustration}`" />
						<div>
							<h3>{{ solution.title }}</h3>

							<div class="mt-3">
								{{ solution.description }}

								<div class="home-solutions__solution-hover flex flex--column flex--align flex--center white">
									<div>
										<h4 class="gilroy sz-6">Explore Templates</h4>
										<ul class="m-0">
											<li v-for="template in solution.templates" :key="template" class="my-1 p-0"><Inline svg="check" class="mr-2" />{{ template }}</li>
										</ul>
									</div>
								</div>
								<div class="sz-4 b7:hide heavy deep mt-3">
									Explore {{ solution.title.toLowerCase() }} <span class="nowrap">templates <inline svg="arrow-right" /></span>
								</div>
							</div>
						</div>
					</c-cta>
				</li>
			</ul>
		</div>

		<div class="text-center">
			<c-cta
				id="93"
				button-text="Explore More Industries"
				url="/templates/industry"
				type="Templates"
				position="bottom"
				class="button button--secondary sz-4 mt-4 deep heavy"
				:arrow="true"
			/>
		</div>
	</section>
</template>

<script>
	import CCta from '/src/components/content/cta.vue';

	export default {
		name: 'CData',
		components: {
			CCta
		},
		data: function () {
			return {
				solutions: [
					{
						title: 'Nonprofits',
						illustration: 'appreciate',
						link: '/templates/non-profits',
						fill: 'pudding',
						description: 'Streamline operations and reduce manual tasks, allowing you to focus on impactful work in the community.',
						templates: [
							'Donation forms',
							'Event planning forms',
							'Volunteer application',
							'Grant applications'
						],
						ctaId: '87'
					},
					{
						title: 'Small Business',
						illustration: 'work',
						link: '/templates/sales',
						fill: 'thetis',
						description: 'Simple yet powerful online forms for data collection, offering insight into customer behavior and employee tracking.',
						templates: [
							'Online order forms',
							'Inventory tracking forms',
							'Customer surveys',
							'Employee onboarding forms'
						],
						ctaId: '88'
					},
					{
						title: 'Healthcare',
						illustration: 'hipaa-compliance',
						link: '/templates/healthcare',
						fill: 'abraham',
						description: 'HIPAA-compliant healthcare form templates, eSignatures, and document generation can help you get started.',
						templates: [
							'Patient intake forms',
							'Advance directive forms',
							'Medical release forms',
							'Emergency contact forms'
						],
						ctaId: '89'
					},
					{
						title: 'Software & Tech',
						illustration: 'data-management',
						link: '/templates/information-technology',
						fill: 'abraham',
						description: 'Create a digital transformation strategy by centralizing data collection with online forms. Integrate Cognito Forms into your platform.',
						templates: [
							'Customer service requests',
							'Cyber security compliance',
							'Change requests',
							'UX surveys'
						],
						ctaId: '90'
					},
					{
						title: 'Education',
						illustration: 'school',
						link: '/templates/education',
						fill: 'pudding',
						description: 'Engage students, parents, and staff with online data collection while maintaining privacy regulations.',
						templates: [
							'Enrollment forms',
							'Student surveys',
							'Scholarship applications',
							'Donation forms'
						],
						ctaId: '91'
					},
					{
						title: 'Government',
						illustration: 'government',
						link: '/templates/local-government',
						fill: 'thetis',
						description: 'Connect with the people you serve. Simplify the process of collecting information, requests, and payments.',
						templates: [
							'Event registration forms',
							'Utility payment forms',
							'Permit applications',
							'FOIA requests'
						],
						ctaId: '92'
					}
				]
			};
		}
	};
</script>

<style lang="scss">
.home-solutions {

	.i-complex {
		flex-shrink: 0;
		width: 80px;
		height: auto;

		@include b-down($b5) {
			width: 45px;
		}
	}

	li {
		@include b-range($b5, $b6) {

			:root & a {
				padding: 1.4em;
			}
		}

		@include b-up($b5) {
			padding: $space__1;
		}

		@include b-up($b6) {
			padding: $space__3;
		}
	}

	&__solution {
		position: relative;
		flex-direction: column;

		@include b-up($b7) {
			align-items: flex-start;
			flex-direction: row;
		}

		> i {
			margin-right: 0;

			@include b-up($b7) {
				align-self: center;
				margin-left: $space__3;
			}

			@include b-up($b7) {
				order: 1;
			}

			@include b-down($b5) {
				position: absolute;
				right: $gutter;
				top: 24px;
				width: 48px;
			}
		}

		&-hover {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			background: rgba($matins, 95%);
			opacity: 0;
			transition: opacity $speed__hovered;

			@include b-down($b7) {
				display: none;
			}
		}

		&:hover .home-solutions__solution-hover {
			opacity: 1;
		}
	}
}
</style>