<template>
	<section class="home-awards wrapper flex flex--align flex--center p pb-6" :class="`home-awards--${awardsAmount}`">
		<img v-for="(award, key) in awards" :key="key" :src="award.image" :alt="award.altText" loading="lazy" width="770" height="1000" />
	</section>
</template>

<script>
	import filterImageObject from '/src/utilities/filter-wiki-images-object';

	export default {
		name: 'Awards',
		inject: ['siteManifest'],
		data() {
			return {
				awards: undefined
			};
		},
		computed: {
			awardsAmount() {
				return this.awards ? Object.keys(this.awards).length : 0;
			}
		},
		mounted() {
			const awardsManifest = this.siteManifest.company.lookup.byCfid[42];
			this.awards = filterImageObject(awardsManifest.images, awardsManifest.altText);
		}
	};
</script>

<style lang="scss">
.home-awards {
	$featuredAwardSize: 155px;
	gap: min($gutter*2, 5vw);

	img {
		width: 100px;
	}

	&--1 {

		img:nth-child(1) {
			width: $featuredAwardSize;
		}
	}

	&--3 {

		img:nth-child(2) {
			width: $featuredAwardSize;
		}

		@include b-down($b6) {

			img {
				max-width: 24vw;
			}
		}
	}

	&--5 {

		img:nth-child(3) {
			width: $featuredAwardSize;
		}

		@include b-down($b6) {

			img {
				max-width: 24vw;

				&:nth-child(2),
				&:nth-child(4) {
					display: none;
				}
			}
		}
	}
}
</style>