<template>
	<section class="home-teams wrapper pt-6 mb-6">
		<div class="home-teams__header flex">
			<div class="mb-4">
				<h2 class="gilroy sz-7 b7:sz-8">The ultimate tool for every&nbsp;team</h2>
				<p class="lede sz-5 b7:sz-6">When off-the-shelf software doesn't suit your needs, customize Cognito Forms to be a perfect fit for your team.</p>
			</div>
			<c-concentric-image
				class="b10:show flex--no-shrink ml-4 b9:bm-0"
				:ring-colors="['summer', 'hitchcock', 'peach']"
				rotate-direction="counterclockwise"
			>
				<img
					v-for="tab of tabs"
					v-show="selectedTab === tab.header"
					:key="tab.header"
					:src="require(`@cognitoforms/shared-components/src/assets/graphics/images/home/${tab.image.src}`)"
					:alt="tab.image.alt"
					loading="lazy"
					width="300"
					height="300"
				>
			</c-concentric-image>
		</div>
		<div class="home-teams__content flex mt-4">
			<div class="home-teams__buttons b10:show w-25 flex--no-shrink mr-5">
				<button
					v-for="tab of tabs"
					:id="'team-tab--' + tab.id"
					:key="tab.header"
					class="home-teams__button button--no-style p-3"
					:class="{ 'is-active' : selectedTab === tab.header }"
					@click="selectedTab = tab.header"
				>
					<span class="gilroy heavy">{{ tab.header }}</span>
					<inline svg="chevron-right" />
				</button>
			</div>
			<div class="home-teams__tabs flex--grow matins">
				<template v-for="tab of tabs">
					<div
						:key="tab.header"
						class="home-teams__tabs-wrapper"
					>
						<button
							class="home-teams__button b10:hide button--no-style py-3 px-4"
							:class="{ 'is-active' : selectedTab === tab.header }"
							@click="handleMobileTabClick($event, tab.header)"
						>
							<span class="gilroy heavy sz-4">{{ tab.header }}</span>
							<inline svg="chevron-right" />
						</button>
						<c-expandable
							:key="tab.header"
							:expanded="selectedTab === tab.header"
							:class="{ 'b10:hide' : selectedTab !== tab.header }"
						>
							<div class="home-teams__tabs-content p-4">
								<p class="heavy pt-0">{{ tab.text }}</p>
								<p class="gilroy sz-5">{{ tab.lede }}</p>

								<ul class="matins">
									<li
										v-for="bullet of tab.list"
										:key="bullet"
										class="flex"
									>
										<inline svg="check" class="deep mt-1 mr-2" />{{ bullet }}
									</li>
								</ul>

								<div class="flex flex--align mt-4">
									<c-cta
										:id="tab.ctaTemplate.id"
										:button-text="tab.ctaTemplate.text"
										:url="tab.ctaTemplate.link"
										type="Templates"
										position="middle"
										class="button button--secondary deep heavy sz-4"
										:arrow="true"
									/>
									<c-cta
										:id="tab.ctaSignup.id"
										button-text="Get Started"
										type="Sign Up"
										position="middle"
										class="button button--primary bg-jaywalk  sz-4 ml-3"
									/>
								</div>
							</div>
						</c-expandable>
					</div>
				</template>
			</div>
		</div>
	</section>
</template>

<script>
	import CConcentricImage from './HomeConcentricImage.vue';
	import CExpandable from '@cognitoforms/shared-components/src/components/interaction/Expandable.vue';
	import CCta from 'src/components/content/cta.vue';

	export default {
		name: 'CTeams',
		components: {
			CConcentricImage,
			CExpandable,
			CCta
		},
		data() {
			return {
				selectedTab: 'Human Resources',
				tabs: [
					{
						header: 'Human Resources',
						id: 'human-resources',
						image: {
							src: 'human-resources-team.webp',
							alt: 'Human resource manager shaking hands with an applicant'
						},
						text: 'Having consistent and complete employee data allows you to serve your workforce and provide talent management.',
						lede: 'Focus on relationships, not moving paperwork along.',
						list: [
							'Streamline your hiring workflow.',
							'Automate employee onboarding.',
							'Increase employee engagement.',
							'Simplify performance evaluations.'
						],
						ctaSignup: {
							id: '76'
						},
						ctaTemplate: {
							id: '81',
							text: 'HR Templates',
							link: '/templates/human-resources'
						}
					},
					{
						header: 'Marketing',
						id: 'marketing',
						image: {
							src: 'marketing-team.webp',
							alt: 'Marketer working on a laptop'
						},
						text: 'Automate your marketing efforts to provide a better customer experience, solidify your brand value, and deliver actionable leads, all to boost conversion.',
						lede: 'Make data-driven decisions, with consistent, accurate data.',
						list: [
							'Generate leads.',
							'Manage customer data.',
							'Gather customer feedback.',
							'Conduct market research.'
						],
						ctaSignup: {
							id: '77'
						},
						ctaTemplate: {
							id: '82',
							text: 'Marketing Templates',
							link: '/templates/marketing-forms'
						}
					},
					{
						header: 'Sales',
						id: 'sales',
						image: {
							src: 'sales-team.webp',
							alt: 'Sales man talking with his hands'
						},
						text: 'Track lead conversion from initial interest to closing the deal with Workflow automation and expedite your sales process.',
						lede: 'Focus on relationships, not moving paperwork along.',
						list: [
							'Enhance communication.',
							'Gather signature and payments.',
							'Negotiate and adjust contracts within the form.',
							'Generate receipts, signed contract docs and more.'
						],
						ctaSignup: {
							id: '78'
						},
						ctaTemplate: {
							id: '83',
							text: 'Sales Templates',
							link: '/templates/sales'
						}
					},
					{
						header: 'Information Technology',
						id: 'information-technology',
						image: {
							src: 'it-team.webp',
							alt: 'Information technologist working remotely from a laptop'
						},
						text: 'Create a digital transformation strategy by centralizing data collection with online forms. Apply conditional logic to route requests to appropriate team members.',
						lede: 'Ditch email requests and automate your IT workflow.',
						list: [
							'Manage service requests.',
							'Track software and hardware inventory.',
							'Stay compliant to data privacy regulations.',
							'Perform IT audits with checklist forms.'
						],
						ctaSignup: {
							id: '79'
						},
						ctaTemplate: {
							id: '84',
							text: 'IT Templates',
							link: '/templates/information-technology'
						}
					},
					{
						header: 'Project Management',
						id: 'project-management',
						image: {
							src: 'project-management-team.webp',
							alt: 'Project manager working on a computer with coffee'
						},
						text: 'Use automated Workflow for staying on top of your projects. Collaborate with internal and external teams to collect requests, signatures, files, and approvals.',
						lede: 'You have enough on your plate, automate.',
						list: [
							'Manage service and change requests.',
							'Identify and track issues.',
							'Negotiate and finalize vendor agreements.',
							'Assign tasks to engage the right people at the right time.'
						],
						ctaSignup: {
							id: '80'
						},
						ctaTemplate: {
							id: '85',
							text: 'Templates',
							link: '/templates/customer-service'
						}
					}
				]
			};
		},
		methods: {
			handleMobileTabClick(e, tab) {
				this.selectedTab = tab;
				const targetEl = e.target.closest('button');

				setTimeout(() => {
					const vh = (window.innerHeight || document.documentElement.clientHeight);
					const containerRect = targetEl.parentNode.getBoundingClientRect();
					if (Math.abs(containerRect.top + containerRect.height - vh) > 50)
						targetEl.scrollIntoView({ behavior: 'smooth' });
				}, 300);
			}
		}
	};
</script>

<style lang="scss">
.home-teams {
	overflow: hidden;

	@include b-up($b10) {
		padding-right: 4rem;
		padding-left: 4rem;
	}

	&__header {
		align-items: flex-end;

		@include b-up($b10) {
			margin-bottom: $gutter * 2;
		}
	}

	&__buttons {
		border-top: 1px solid $brady;
	}

	&__button {
		scroll-margin-top: 75px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		border-bottom: 1px solid $brady;
		color: $ansel;
		transition: all .2s;

		&:not(.is-active):hover {
			background-color: $arnold;
		}

		&.is-active {
			background-color: $deep;
			color: white;

			@include b-down($b10) {

				.i-chevron-right {
					transform: rotate(90deg);
				}
			}
		}

		@include b-range($b10, 1200px) {
			font-size: 15px;
		}
	}

	&__tabs {

		&-wrapper {
			@include b-down($b10) {
				border-top: 1px solid $brady;

				@include unwrap;

				.expandable-container {
					background-color: $puff;
				}
			}

			@include b-up($b10) {
				// Ensure expandable is always open for selected tab on desktop
				.expandable-container {
					transition: unset;

					&:not(.b10-hide) {
						max-height: unset !important;
						overflow: visible !important;
					}
				}
			}

			ul {
				@include b-up($b4) {
					display: flex;
					gap: 1rem;
					flex-wrap: wrap;

					li {
						width: calc(50% - 2rem);
					}
				}

				li {
					align-items: flex-start;
				}
			}

			.button--secondary {
				border-color: $deep;

				.i-arrow-right {
					margin-left: $space__3;
				}
			}
		}

		@include b-up($b10) {

			:root &-content {
				padding: 0;
			}
		}

		@include b-down($b6) {

			.i-arrow-right {
				display: none;
			}
		}
	}

	@include b-down($b6) {

		.cta__arrow {
			display: none;
		}
	}
}
</style>