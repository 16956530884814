<template>
	<section class="security-support-compliance wrapper pt-4 my-5 b6:flex flex--sb">
		<div class="security-support-compliance__item b9:w-33 flex mb-4">
			<inline svg="complex-icons/security" class="flex--no-shrink fill-abraham" />
			<div>
				<h2 class="sz-5">Security</h2>
				<ul class="matins">
					<li class="flex"><inline svg="check" class="deep mt-1 mr-2" />Single Sign-On</li>
					<li class="flex"><inline svg="check" class="deep mt-1 mr-2" />Two Factor Authentication</li>
					<li class="flex"><inline svg="check" class="deep mt-1 mr-2" />Data Encryption & Privacy</li>
				</ul>
			</div>
		</div>
		<div class="security-support-compliance__item b9:w-33 flex mb-4">
			<inline svg="complex-icons/support" class="flex--no-shrink fill-abraham" />
			<div>
				<h2 class="sz-5">Support</h2>
				<ul class="matins">
					<li class="flex"><inline svg="check" class="deep mt-1 mr-2" />Helpful and skilled support answers every ticket</li>
				</ul>
			</div>
		</div>
		<div class="security-support-compliance__item b9:w-33 flex mb-4">
			<inline svg="complex-icons/hipaa-compliance" class="flex--no-shrink fill-abraham" />
			<div>
				<h2 class="sz-5">Compliance</h2>
				<ul class="matins">
					<li class="flex"><inline svg="check" class="deep mt-1 mr-2" />GDPR</li>
					<li class="flex"><inline svg="check" class="deep mt-1 mr-2" />HIPAA</li>
					<li class="flex"><inline svg="check" class="deep mt-1 mr-2" />CCPA</li>
				</ul>
			</div>
		</div>
	</section>
</template>
<script>
	export default {
		name: 'CSecuritySupportCompliance'
	};
</script>

<style lang="scss">
.security-support-compliance {
	border-top: 1px solid $lange;
	border-bottom: 1px solid $lange;
	gap: $space__4;

	@include b-up($b12) {
		gap: $space__6;
	}

	&__item {
		gap: $space__4;

		@include b-range($b6, $b9) {
			flex-direction: column;
			width: 30%;
		}

		> i {
			width: 78px;
			height: 78px;
		}

		li {
			align-items: flex-start;
		}
	}
}
</style>