<template>
	<section class="home-workflow py-6 relative">
		<svg width="360" height="360" viewBox="0 0 360 360" class="home-workflow__orbit-s absolute">
			<g>
				<circle r="160" cx="180" cy="180" />
				<circle r="175" cx="180" cy="180" stroke-width="5" />
			</g>
		</svg>
		<svg width="900" height="900" viewBox="0 0 900 900" class="home-workflow__orbit-m absolute">
			<g>
				<circle r="400" cx="450" cy="450" stroke-width="15" />
				<circle r="440" cx="450" cy="450" stroke-width="6" />
				<circle r="330" cx="450" cy="450" stroke-width="20" />
			</g>
		</svg>
		<svg width="1700" height="1700" viewBox="0 0 1700 1700" class="home-workflow__orbit-l absolute">
			<g>
				<circle r="650" cx="850" cy="850" stroke-width="88" />
				<circle r="752" cx="850" cy="850" stroke-width="3" />
				<circle r="805" cx="850" cy="850" stroke-width="30" />
				<circle r="750" cx="850" cy="850" stroke-width="55" />
				<circle r="830" cx="850" cy="850" stroke-width="1" />
			</g>
		</svg>
		<inline svg="complex-icons/workflow" class="home-workflow-icon fill-thetis block mx-auto" />
		<div class="wrapper wrapper--1">
			<h2 class="text-center gilroy matins">Automate with Workflow</h2>
			<h3 class="text-center light">Power your business processes so you can work&nbsp;more&nbsp;efficiently.</h3>
			<p class="text-center">Whether there are one or one thousand entries daily, acting on that data is vital to business function. Workflow with Cognito Forms engages the right people at the right time.</p>
			<dl class="b7:flex flex--wrap">
				<dt class="deep heavy gilroy sz-5 flex flex--align b7:w-33 mt-4"><inline svg="role" class="mr-3 platon" />User Roles</dt>
				<dd class="b7:w-66">Internal and external ‘User Roles’ identify the people involved in your processes.</dd>
				<dt class="deep heavy gilroy sz-5 flex flex--align b7:w-33 mt-4"><inline svg="action" class="mr-3 platon" />Status + Action</dt>
				<dd class="b7:w-66">Display the data that is necessary for each status. Allow specific roles to perform an action and move the status along.</dd>
				<dt class="deep heavy gilroy sz-5 flex flex--align b7:w-33 mt-4"><inline svg="multiple-emails" class="mr-3 platon" />Notifications</dt>
				<dd class="b7:w-66">Email notifications keep your customers and team in the loop as the status changes. Automatic customized links prompt users to take action or review data.</dd>
				<dt class="deep heavy gilroy sz-5 flex flex--align b7:w-33 mt-4"><inline svg="success" class="mr-3 platon" />Workflow Tasks</dt>
				<dd class="b7:w-66">Automatic in-app task assignments. As the status changes, Workflow Actions can trigger Task assignments to specific Users or Roles.</dd>
			</dl>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'CWorkflow'
	};
</script>

<style lang="scss">
.home-workflow {
	$size__bg: 846px;
	$scroll-offset: 650;

	&__orbit-s,
	&__orbit-m,
	&__orbit-l {
		left: 50%;
		top: 170px;
		z-index: $z-1;
		max-width: none;
		transform: translate(-50%, -50%);
		fill: none;
		stroke: $harriet;

		g,
		g > * {
			transform-origin: center;
		}
	}

	&__orbit-l {
		stroke-dasharray: 0% 80% 40% 240%;

		g {
			transform: rotate(220deg);
		}

		g > * {
			transform: rotate(calc((var(--scroll) - #{$scroll-offset}) * .05deg));
		}

		circle:nth-child(1) {
			stroke-dasharray: 0% 90% 60% 210%;
			stroke: $arnold;
		}

		circle:nth-child(2) {
			$dash-array: .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4% .1% .4%;
			stroke-dasharray: 0% 90% $dash-array + $dash-array + $dash-array + $dash-array + $dash-array 0% 220%;
			stroke: $summer;
		}

		circle:nth-child(3) {
			$dash-array: 1% 1% 1% 1% 1% 1% 1% 1% 1% 1%;
			stroke-dasharray: 0% 25% $dash-array + $dash-array + $dash-array + $dash-array 0% 285%;
			transform: rotate(calc((var(--scroll) - #{$scroll-offset}) * -.06deg));
		}

		circle:nth-child(4) {
			$dash-array: .1% .9% .1% .9% .1% .9% .1% .9% .1% .9% .1% .9% .1% .9% .1% .9% .1% .9% .1% .9%;
			transform: rotate(calc((var(--scroll) - #{$scroll-offset}) * .01deg));
			stroke: $thetis;
			stroke-dasharray: 0% 70% $dash-array + $dash-array + $dash-array + $dash-array 0% 250%;
		}

		circle:nth-child(5) {
			$dash-array: .2% 1.8% .2% 1.8% .2% 1.8% .2% 1.8% .2% 1.8%;
			stroke: $casablanca;
			stroke-dasharray: 0% 80% $dash-array + $dash-array + $dash-array + $dash-array 0% 240%;
		}
	}

	&__orbit-m {
		stroke-dasharray: 0% 250% 110%;

		g {
			transform: rotate(190deg);
		}

		g > * {
			transform: rotate(calc((var(--scroll) - #{$scroll-offset}) * -.07deg));
		}

		circle:nth-child(1) {
			transform: rotate(calc((var(--scroll) - #{$scroll-offset}) * .01deg));

			$dash-array: .2% 1.8%.2% 1.8% .2% 1.8% .2% 1.8% .2% 1.8%;
			stroke: $summer;
			stroke-dasharray: 0% 250% $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array;
		}

		circle:nth-child(3) {
			transform: rotate(calc((var(--scroll) - #{$scroll-offset}) * -.065deg));
			stroke: $arnold;

			$dash-array: .5% 1.5% .5% 1.5% .5% 1.5% .5% 1.5% .5% 1.5%;
			stroke-dasharray: 0% 180% $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array;
		}
	}

	&__orbit-s {
		stroke-dasharray: 0% 260% 100%;
		stroke: $pudding;

		g {
			transform: rotate(70deg);
		}

		circle:nth-child(1) {
			$dash-array: 1% 1% 1% 1% 1% 1% 1% 1% 1% 1%;
			transform: rotate(calc((var(--scroll) - #{$scroll-offset}) * -.08deg));
			stroke: $thetis;
			stroke-dasharray: $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array + $dash-array 0% 280%;
		}

		circle:nth-child(2) {
			stroke-dasharray: 0% 260% 100%;
			transform: rotate(calc((var(--scroll) - #{$scroll-offset}) * .095deg));
		}
	}

	@include b-down(1750px) {

		&__orbit-l {
			display: none;
		}
	}

	@include b-down(950px) {

		&__orbit-m {
			display: none;
		}
	}

	@include b-down(380px) {

		&__orbit-l {
			display: none;
		}
	}

	&-icon {
		position: relative;
		width: 136px;
		height: 136px;
	}

	dt {

		i {
			width: $size__icon--xl;
			height: $size__icon--xl;
		}
	}

	dd {
		margin: $space__3 0 0 calc($size__icon--xl + $space__4);

		@include b-up($b7) {
			margin: $space__5 0 0 0;
			padding-left: $space__4;
		}
	}
}
</style>