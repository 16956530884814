<template>
	<section class="home-features py-6 mb-5 bg-puff">
		<div class="wrapper b10:flex flex--sb flex--align">
			<div class=" flex--no-shrink">
				<h3 class="sz-4 uppercase mb-0 platon">Online Form Builder</h3>
				<p class="sz-7 matins gilroy heavy mt-3 mb">
					Simple <span class="light">enough<br class="b10:show">
						for quick setup,</span><br class="b10:show">
					customizable <span class="light"><br class="b10:show">
						for power users</span>
				</p>
			</div>
			<ul class="gilroy matins heavy flex flex--wrap text-center">
				<li class="flex flex--column flex--align w-50 b7:w-33 m-0">
					<inline svg="complex-icons/repeating-sections-tables" class="fill-thetis" />
					Repeating Sections
				</li>
				<li class="flex flex--column flex--align w-50 b7:w-33 m-0">
					<inline svg="complex-icons/lookup-field" class="fill-thetis" />
					Data Lookups
				</li>
				<li class="flex flex--column flex--align w-50 b7:w-33 m-0">
					<inline svg="complex-icons/calculations" class="fill-thetis" />
					Calculations
				</li>
				<li class="flex flex--column flex--align w-50 b7:w-33 m-0">
					<inline svg="complex-icons/integrations" class="fill-thetis" />
					Integrations
				</li>
				<li class="flex flex--column flex--align w-50 b7:w-33 m-0">
					<inline svg="complex-icons/conditional-logic" class="fill-thetis" />
					Conditional Logic
				</li>
				<li class="flex flex--column flex--align w-50 b7:w-33 m-0">
					<inline svg="complex-icons/theme" class="fill-thetis" />
					Custom Themes
				</li>
			</ul>
		</div>
	</section>
</template>
<script>
	export default {
		name: 'CFeatures'
	};
</script>

<style lang="scss">
.home-features {

	h2 {
		margin-right: $space__6;

		@include b-down($b9) {
			margin-right: 0;
			text-align: center;
		}

		span {
			font-size: .94em;
		}
	}

	li {
		padding: $space__4;
		font-size: 18px;
		line-height: normal;
	}

	i {
		width: 72px;
		height: 72px;
	}
}
</style>