<template>
	<layout class="home">
		<c-hero />
		<c-screenshots :tabs="tabs" />
		<c-productivity-stats />
		<c-teams />
		<c-workflow />
		<c-reviews />
		<c-features />
		<c-data />
		<c-security-support-compliance />
		<c-solutions />
		<c-awards />
		<c-integrations-carousel />

		<div class="text-center">
			<c-cta
				id="94"
				button-text="Sign Up For Free"
				url="/signup"
				type="Sign Up"
				position="bottom"
				class="button button--primary sz-5 mb-6"
			/>
		</div>

		<script type="application/ld+json" v-html="ldJson"></script>
	</layout>
</template>

<script>
	import Layout from 'src/layouts/PublicLayout.vue';

	import CHero from './home/Hero.vue';
	import CScreenshots from './home/Screenshots.vue';
	import CReviews from '/src/components/content/Reviews.vue';
	import CTeams from './home/Teams.vue';
	import CWorkflow from './home/Workflow.vue';
	import CProductivityStats from './home/ProductivityStats.vue';
	import CFeatures from './home/Features.vue';
	import CData from './home/HomeData.vue';
	import CSecuritySupportCompliance from './home/SecuritySupportCompliance.vue';
	import CSolutions from './home/Solutions.vue';
	import CAwards from './home/Awards.vue';
	import CIntegrationsCarousel from './home/IntegrationsCarousel.vue';
	import CCta from 'src/components/content/cta.vue';

	export default {
		components: {
			Layout,
			CHero,
			CScreenshots,
			CReviews,
			CTeams,
			CAwards,
			CWorkflow,
			CProductivityStats,
			CFeatures,
			CData,
			CSecuritySupportCompliance,
			CSolutions,
			CIntegrationsCarousel,
			CCta
		},
		inject: ['siteManifest'],
		beforeRouteEnter(to, from, next) {
			to.params.isLanding = from.fullPath === '/';
			next();
		},
		inheritAttrs: false,
		props: {
			isLanding: { type: Boolean, default: false }
		},
		data() {
			return {
				title: 'Cognito Forms: Online Form Builder with Workflow Automation',
				description: 'With Cognito Forms, you can build powerful online forms, collect data, and automate your business workflow - docs, file uploads, signatures, and approvals.',
				tabs: [
					{
						title: 'Signatures',
						icon: 'signature',
						text: ['Capture legally binding signatures.'],
						images: [
							{
								name: 'signature-base.webp',
								alt: 'Cognito Forms online form builder with a signature field added to the bottom of a contract form. The form displays contract details with a checkbox stating I agree to these terms directly above a signature field. The signature field settings are adjusted to allow both Draw and Type options for signing. Options Always Show This Field and Always Require This Field are selected, indicating the signature field is mandatory and always visible, with other conditional settings options available but not selected.'
							},
							{
								name: 'signature-phone.webp',
								alt: 'Text-generated signature added to online contract form on a mobile phone. I agree to these terms is checked above the signature box with an orange submit button below.',
								phone: true
							}
						]
					},
					{
						title: 'Payment',
						icon: 'credit-card',
						images: [
							{
								name: 'payment-base.webp',
								alt: 'Cognito Forms online form builder with a payment field added to an order form. The option to Always process payment is selected, when, and never are the other options. There is an option to allow customers to keep their cards on file.'
							},
							{
								name: 'payment-modal.webp',
								alt: 'Accept payments on your form pop-up modal when setting up payment settings on the Cognito Forms online form builder. The payment options include PayPal with Venmo, Stripe with Google Pay and Apple Pay, and Square point of sale.'
							},
							{
								name: 'payment-phone.webp',
								alt: 'The checkout process on an online form on a phone with pay with the card selected; Google Pay and Apple Pay are other options. Checkout details include product price, subtotal, and amount due.',
								phone: true
							}
						]
					},
					{
						title: 'Generated Docs',
						icon: 'generated-documents',
						text: ['Automatically generate and send documents.'],
						images: [
							{
								name: 'documents-base.webp',
								alt: 'Cognito Forms online form builder setting up document generation on a purchase order form. The workflow action settings panel with a custom confirmation message to include a document.'
							},
							{
								name: 'documents-paper.webp',
								alt: 'Receipt PDF generated from an online purchase order form. Includes company contact information, billing information, purchase details, and payment details. Document Generated export options include Microsoft Word or Adobe PDF.'
							}
						]
					},
					{
						title: 'Workflow',
						icon: 'workflow',
						images: [
							{
								name: 'workflow-base.webp',
								alt: 'Cognito Forms Workflow flow chart of a service business and how they process a service request. The customer submits a request, and the status changes to Service Requested. Service Coordinator Schedules Service and status changes to Scheduled. The Service Provider Performs the Service, and the status changes to Complete.'
							},
							{
								name: 'workflow-status.webp',
								alt: 'Edit Status pop-up modal sets status changes when actions are performed. The status name is complete, and the dropdown color option is set to green. Automatically Assign Status is conditionally set to When.'
							},
							{
								name: 'workflow-role.webp',
								alt: 'Edit Role pop-up modal sets what role is notified to take action after an action is performed. The Role Name is Service Provider, Role Type is Other. Anyone with Admin or Editor permissions have full access and Jessica Jones is assigned limited access.'
							}
						]
					},
					{
						title: 'Workflow Tasks',
						icon: 'tasks',
						text: ['All form entries you need to take action on will show up in Tasks on your dashboard.', 'Bring the right people into your workflow at the right time, with automatic in-app Task notifications.'],
						images: [
							{
								name: 'workflow-tasks-base.webp',
								alt: 'Cognito Forms online form builder dashboard. In the list of all forms in the organization, the task section is selected. Tasks show only the forms assigned to the logged in user with an orange notification badge indicating how many entries on that form require action.'
							},
							{
								name: 'grid-view-settings.webp',
								alt: 'While building a form, Grid view settings pop-up modal allows tasks to be automatically assigned to specific Roles or Users as Tasks. There are additional options to limit user access on the form to create or share entries'
							}
						]
					},
					{
						title: 'Data',
						icon: 'data',
						text: ['View the right data for each role.', 'Import, export, and share data.'],
						images: [
							{
								name: 'data-base.webp',
								alt: 'Cognito Forms online form builder entries list of a Timesheet online form. Entries show the Entry Status of approved, pending, and incomplete. Entries show filled-out form data, including Employee Role, Name, time Period, PTO Hours, Work hours, and Total Hours.  The All Timesheets view is selected, other saved views are Pending Timesheets, Team Timesheets, and My Timesheets. View options include Grid View and Form View.'
							},
							{
								name: 'data-modal.webp',
								alt: 'Share Entry pop up modal sets unique entries views and give access to view entries. Manager Link Type is in the dropdown and set to never expire. It’s set to share with a Specific Person. This form is currently Shared With Mike Johnson as Manager Role, Jane Schmidt Public Role, and Emily Williams Internal Role.'
							}
						]
					},
					{
						title: 'Approvals',
						icon: 'approvals',
						text: ['Employee submits timesheet.', 'Manager approves timesheet.'],
						images: [
							{
								name: 'approval-base.webp',
								alt: 'Timesheet online form on the Entries page of Cognito Forms online form builder. The timesheet form shows the account worked on the category of work being done and allows for time addition Monday through Sunday. A repeating section button to Add Time will add an additional data collection line.'
							},
							{
								name: 'approval-phone.webp',
								alt: 'Email message on a mobile phone with a Workflow link button for the manager to approve the timesheet of the employee.',
								phone: true
							}
						]
					}

				]
			};
		},
		head() {
			return {
				title: this.title,
				titleTemplate: '%s',
				meta: [
					{ name: 'description', content: this.description, id: 'desc' },
					{ property: 'og:image', content: this.socialImage, id: 'og:image' },
					{ property: 'og:title', content: this.title, id: 'og:title' },
					{ property: 'twitter:title', content: this.title, id: 'twitter:title' },
					{ property: 'og:description', content: this.description, id: 'og:description' },
					{ property: 'twitter:description', content: this.description, id: 'twitter:description' }
				],
				link: [
					{ rel: 'canonical', href: 'https://www.cognitoforms.com/', id: 'canonical' }
				]
			};
		},
		computed: {
			socialImage() {
				return require('@cognitoforms/shared-components/src/assets/graphics/images/og-home.png');
			},
			ldJsonImages() {
				return [
					require('@cognitoforms/shared-components/src/assets/graphics/logos/ldjson/1x1/logo.png'),
					require('@cognitoforms/shared-components/src/assets/graphics/logos/ldjson/4x3/logo.png'),
					require('@cognitoforms/shared-components/src/assets/graphics/logos/ldjson/16x9/logo.png')
				];
			},
			ldJson() {
				return {
					'@context': 'http://schema.org',
					'@type': 'Article',
					'mainEntityOfPage': {
						'@type': 'WebPage',
						'@id': 'https://www.cognitoforms.com'
					},
					'headline': this.title,
					'image': this.ldJsonImages,
					'datePublished': '2013-12-10',
					'author': {
						'@type': 'Organization',
						'name': 'Cognito Forms'
					},
					'publisher': {
						'@type': 'Organization',
						'name': 'Cognito Forms',
						'logo': {
							'@type': 'ImageObject',
							'url': require('@cognitoforms/shared-components/src/assets/graphics/images/logo.png'),
							'width': 378,
							'height': 60
						}
					},
					'description': this.description
				};
			}
		}
	};
</script>

<style lang="scss">
@import '@cognitoforms/styles/website/flex-columns';

.home section {
	font-size: 18px;
}

.home-productivity-stats + .home-teams {
	padding-top: clamp(70px, 17vw, 96px);
}

.home-teams + .home-workflow {
	margin-top: clamp(100px, 17vw, 180px);
}

.home .screenshots + .home-productivity-stats {
	margin-top: $gutter * 2;
}
</style>